document.addEventListener('DOMContentLoaded', function(){

    if($('.tale_start').length)
        $('.tale_start').closest('p').css('min-height', '80px');

    let lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
    let lazyActive = false;
    let lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

    const lazyLoad = function() {
        if (lazyActive === false) {
            lazyActive = true;

            setTimeout(function() {
                lazyImages.forEach(function(lazyImage) {
                    if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== 'none') {
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove('lazy');

                        lazyImages = lazyImages.filter(function(image) {
                            return image !== lazyImage;
                        });

                        if (lazyImages.length === 0) {
                            document.removeEventListener('scroll', lazyLoad);
                            window.removeEventListener('resize', lazyLoad);
                            window.removeEventListener('orientationchange', lazyLoad);
                        }
                    }
                });

                lazyActive = false;
            }, 200);
        }
    };

    lazyLoad();

    document.addEventListener('scroll', lazyLoad);
    window.addEventListener('resize', lazyLoad);
    window.addEventListener('orientationchange', lazyLoad);

    if ('IntersectionObserver' in window) {
        let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    lazyBackgroundObserver.unobserve(entry.target);
                }
            });
        });

        lazyBackgrounds.forEach(function(lazyBackground) {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }

    const maxSymbolsForTextarea = 3000;
    let commentTextarea = jQuery('#comment_textarea');
    if(commentTextarea.length > 0){
        commentTextarea.EnsureMaxLength({
            limit: maxSymbolsForTextarea,
            separator: ' из ',
            placement: '#comment_textarea-counter'
        });
    }

    let textareaExpanded = jQuery('textarea[data-expanded]');
    if(textareaExpanded.length > 0){
        autosize(textareaExpanded);
    }

    let inputFilterCategories = jQuery('#filter_categories');
    if(inputFilterCategories.length > 0){
        jQuery(document).on('input', '#filter_categories', function(){
            let filterValue = jQuery(this).val();

            if(filterValue.length > 1){
                jQuery(".main_categories_block [data-category-name]").removeClass('d-flex').addClass('d-none');
                jQuery(".main_categories_block [data-category-name*='"+filterValue+"']").removeClass('d-none').addClass('d-flex');
            }else{
                jQuery(".main_categories_block [data-category-name]").removeClass('d-none').addClass('d-flex');
            }
        });
    }

    if(jQuery('form.validate').length > 0) {
        jQuery.validator.setDefaults({
            debug: false
            ,success: 'valid'
            ,errorPlacement: function(error, element) {
                if(element.is(':text[class="form-control"]')){
                    error.insertAfter(element.closest('.form-group'));
                } else { // This is the default behavior of the script
                    error.insertAfter(element);
                }
                console.log(error);
            }
            ,errorElement: 'span'
        });

        let commentForm = jQuery('form.validate[id^="commentForm"]');
        if(commentForm.length > 0) {
            commentForm.validate({
                rules: {
                    comment_text: {
                        required: true,
                        normalizer: function( value ) {
                            return jQuery.trim( value );
                        },
                        minlength:7,
                        maxlength:maxSymbolsForTextarea
                    }
                },
                submitHandler: function (form, event) {
                    event.preventDefault();
                    form.submit();
                }
            });
        }

        let fullFormPartialPayment = jQuery('form.validate[id^="form-partial-payment"]');
        if(fullFormPartialPayment.length > 0) {

            let form_options = {
                resetForm: true,
                url: '/ajax/partial_payment',
                type: 'post',
                dataType: 'json',
                success: function (json) {
                    console.log(json);

                    if(json['success']){
                        window.location = json['redirect'];
                    }
                }
            };

            fullFormPartialPayment.each(function(){
                let _this= jQuery(this);
                _this.validate({
                    ignore:[],
                    rules: {
                        monthly_payment: {
                            required: true
                        }
                    },
                    messages: {
                        monthly_payment: 'Количество платежей'
                    },
                    submitHandler: function (form, event) {
                        event.preventDefault();
                        jQuery(form).ajaxSubmit(form_options);
                    }
                });
            });
        }
    }

    const owlBasicConfig = {
        loop: false,
        nav: false,
        rewind: true,
        navText: ['<i class="far fa-long-arrow-left"></i>','<i class="far fa-long-arrow-right"></i>'],
        dots: false,
        lazyLoad: true,
        lazyLoadEager: 2,
        autoplay: false,
        smartSpeed: 450
    };

    let owlCarousel = jQuery('.owl-carousel');
    if(owlCarousel.length > 0){

        let owlAlbum = jQuery('.owl-carousel.owl-album');
        let owlVideoAlbum = jQuery('.owl-carousel.owl-video-album');
        let owlArticle = jQuery('.owl-carousel.owl-wedding-plan');
        let owlTopCompanies = jQuery('.owl-carousel.owl-top-companies');

        owlArticle.each(function(){
            let _thisOwl = jQuery(this);

            let _owl_quantity = parseInt(_thisOwl.attr('data-quantity'));
            let _owl_margin = parseInt(_thisOwl.attr('data-margin'));

            if(!_owl_quantity || _owl_quantity <= 0){
                _owl_quantity = 3;
            }

            if(!_owl_margin || _owl_margin < 0){
                _owl_margin = 0;
            }

            let _owlCurConfig = Object.assign({}, owlBasicConfig, {
                items: _owl_quantity,
                margin: _owl_margin,
                video: false,
                autoWidth:false,
                responsive: {
                    // breakpoint from 0 up
                    0 : {
                        items:1
                    },
                    // breakpoint from 768 up
                    768 : {
                        items:2
                    },
                    // breakpoint from 992 up
                    992 : {
                        items:3
                    },
                    // breakpoint from 1200 up
                    1200 : {
                        items:_owl_quantity
                    }
                },
            });

            _thisOwl.owlCarousel(_owlCurConfig);

            let _next = _thisOwl.parent().find('.owl-custom-nav.owl-next');
            let _prev = _thisOwl.parent().find('.owl-custom-nav.owl-prev');

            _next.click(function() {
                _thisOwl.trigger('next.owl.carousel');
            });

            _prev.click(function() {
                _thisOwl.trigger('prev.owl.carousel');
            });
        });

        owlAlbum.each(function(){
            let _thisOwl = jQuery(this);

            let _owl_quantity = parseInt(_thisOwl.attr('data-quantity'));
            let _owl_margin = parseInt(_thisOwl.attr('data-margin'));

            if(!_owl_quantity || _owl_quantity <= 0){
                _owl_quantity = 4;
            }

            if(!_owl_margin || _owl_margin < 0){
                _owl_margin = 0;
            }

            let _owlCurConfig = Object.assign({}, owlBasicConfig, {
                items: _owl_quantity,
                margin: _owl_margin,
                video: false,
                autoWidth:true,
                onLoadedLazy:function(){
                    _thisOwl.trigger('refresh.owl.carousel');
                    console.log('onLoadedLazy => refresh.owl.carousel');
                },
                responsive: {
                    // breakpoint from 0 up
                    0 : {
                        items:1
                    },
                    // breakpoint from 768 up
                    768 : {
                        items:2
                    },
                    // breakpoint from 992 up
                    992 : {
                        items:3
                    },
                    // breakpoint from 1200 up
                    1200 : {
                        items:_owl_quantity
                    }
                },
            });

            _thisOwl.owlCarousel(_owlCurConfig);

            let _next = _thisOwl.parent().find('.owl-custom-nav.owl-next');
            let _prev = _thisOwl.parent().find('.owl-custom-nav.owl-prev');

            _next.click(function() {
                _thisOwl.trigger('next.owl.carousel');
            });

            _prev.click(function() {
                _thisOwl.trigger('prev.owl.carousel');
            });

            _thisOwl.magnificPopup({
                delegate: 'a.album-image', // child items selector, by clicking on it popup will open
                type: 'image',
                gallery: {
                    enabled: true,
                    preload: [0,1],
                    navigateByImgClick: true,
                    tPrev: 'Предыдущее фото (кнопка влево на клавиатуре)', // title for left button
                    tNext: 'Следующее фото (кнопка вправо на клавиатуре)', // title for right button
                    tCounter: '<span class="mfp-counter">%curr% из %total%</span>' // markup of counter
                },
            });
        });

        owlVideoAlbum.each(function(){
            let _thisOwl = jQuery(this);

            let _owl_quantity = parseInt(_thisOwl.attr('data-quantity'));
            let _owl_margin = parseInt(_thisOwl.attr('data-margin'));

            if(!_owl_quantity || _owl_quantity <= 0){
                _owl_quantity = 4;
            }

            if(!_owl_margin || _owl_margin < 0){
                _owl_margin = 0;
            }

            let _owlCurConfig = Object.assign({}, owlBasicConfig, {
                items: _owl_quantity,
                margin: _owl_margin,
                video: true,
                autoWidth:false,
                responsive: {
                    // breakpoint from 0 up
                    0 : {
                        items:1
                    },
                    // breakpoint from 768 up
                    768 : {
                        items:2
                    },
                    // breakpoint from 992 up
                    992 : {
                        items:3
                    },
                    // breakpoint from 1200 up
                    1200 : {
                        items:_owl_quantity
                    }
                },
            });

            _thisOwl.owlCarousel(_owlCurConfig);

            let _next = _thisOwl.parent().find('.owl-custom-nav.owl-next');
            let _prev = _thisOwl.parent().find('.owl-custom-nav.owl-prev');

            _next.click(function() {
                _thisOwl.trigger('next.owl.carousel');
            });

            _prev.click(function() {
                _thisOwl.trigger('prev.owl.carousel');
            });

            _thisOwl.magnificPopup({
                delegate: 'a.album-video', // child items selector, by clicking on it popup will open
                type: 'iframe',
                disableOn: 700,
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: true,
                fixedContentPos: true,
                iframe: {
                    patterns: {
                        youtube: {
                            index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                            id: 'v=', // String that splits URL in a two parts, second part should be %id%
                            // Or null - full URL will be returned
                            // Or a function that should return %id%, for example:
                            // id: function(url) { return 'parsed id'; }

                            src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
                        },
                        vimeo: {
                            index: 'vimeo.com/',
                            id: '/',
                            src: '//player.vimeo.com/video/%id%?autoplay=1'
                        }
                    },
                }
            });
        });

        owlTopCompanies.each(function(){
            let _thisOwl = jQuery(this);

            let _owlCurConfig = Object.assign({}, owlBasicConfig, {
                animateIn: 'fadeIn',
                animateOut: 'fadeOut',
                nav: false,
                dots: false,
                items: 1,
                margin: 15,
                video: false,
                autoplay: true,
                autoplayHoverPause: true,
                autoWidth: false,
                autoHeight: true,
                touchDrag: false,
                mouseDrag: false,
                responsive: {
                    // breakpoint from 0 up
                    0 : {
                        items:1
                    },
                    // breakpoint from 768 up
                    768 : {
                        items:1
                    },
                    // breakpoint from 992 up
                    992 : {
                        items:1
                    },
                    // breakpoint from 1200 up
                    1200 : {
                        items:1
                    }
                },
            });

            _thisOwl.owlCarousel(_owlCurConfig);

            if (_owlCurConfig.touchDrag === false && _owlCurConfig.mouseDrag === false) {
                addSwipeEventsForAnimation(this, _thisOwl);
            }
        });
    }

    function addSwipeEventsForAnimation(node, $carouselObject) {
        var hamEvents = new Hammer(node, {});

        hamEvents.on("swiperight", function(){
            $carouselObject.trigger('prev.owl.carousel');
        });

        hamEvents.on("swipeleft", function(){
            $carouselObject.trigger('next.owl.carousel');
        });

        hamEvents.on("tap", function(el){
            if(jQuery(el.target).hasClass('owl-next') || jQuery(el.target).parent().hasClass('owl-next')){
                $carouselObject.trigger('next.owl.carousel');
            }

            if(jQuery(el.target).hasClass('owl-prev') || jQuery(el.target).parent().hasClass('owl-prev')){
                $carouselObject.trigger('prev.owl.carousel');
            }
        });

        $carouselObject.on('drag.owl.carousel',function(){
            $carouselObject.trigger('translated.owl.carousel');
        });
    }
    
});

